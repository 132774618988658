.mobinfo {
    display: inline-block;
}

:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 15vh; /* 15vh */
    /* Slick */
    --slick-slider-height: 85vh;
    /* Colors */
    --text-color: #121212;
    --main-color: #fff;
    --accent-color: #E20613;
    --effect-color: #E20613;
    --header-text-color: #121212;
   --header-bg-color: rgba(243,243,243,0.5);
    --footer-bg-color: var(--header-bg-color);
    --footer-text-color: #121212;
    --menu-bg-color: transparent;
    --menu-text-color: inherit;
    --menu-text-hover-color: inherit;
    --menu-text-bg-color: transparent;
    --menu-text-bg-hover-color: inherit;
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Quicksand, sans-serif;
    --alternative-font-family: AbrilFatface, serif;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
/*
@import "/cssmodules/slick.css";
@import "/cssmodules/magnific-popup.css";
*/
/*@import "/cssmodules/animate.css";*/
@import "/cssmodules/menu.css";
/* @import "/cssmodules/font-awesome.css"; */
@import "/cssmodules/baguetteBox.min.css";
@import "/fontmodules/Quicksand.css";
@import "/fontmodules/AbrilFatface.css";

/*
@import "/cssmodules/photoswipe.css";

*/

.grid-item { width: calc(20% - 0.5rem); margin: 0.25rem }

.grid{
    width: 100%;
    margin-top: 15vh;
}

.b-lazy img{
    opacity: 0;
}

.b-lazy{
    overflow: hidden;
    background-size: cover;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.b-loaded {
    animation-duration: 1s;
    animation-name: fadeInUp;
    animation-fill-mode: backwards;
    opacity: 1;
    height: auto !important;
    padding-bottom: 0 !important;
}

html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: var(--text-color);
    background-color: #f3f3f3;
   
}

body.startsida {

 margin: 0 .8rem;
}

body.fixed {
    border-top-color: rgba(243,243,243,0.5);
    
}

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

header {
    background: var(--header-bg-color);
    color: var(--header-text-color);
    z-index: 100;
}

footer {
    text-align: center;
    padding: 1rem 0;
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
}

a {text-decoration: none;}

header a {
    text-decoration: none;
}

.logo,
.logo h1 {
    font-size: calc(1.3 * var(--scale-factor) * var(--scale-factor)rem);
    text-transform:none;
}

.logo a,
.logo a h1 {
    margin:0;
    font-weight: 300;
    font-family: var(--alternative-font-family);
}

.container {
    max-width: 1100px;;
    padding: .0em;
    width: 100%;
    background: rgba(255,255,255,0.8);
    margin-top:10em; /* kåss */
}
.clients article, .about article, .cecilia-lundgren article {padding: 0.5em 3em;}
img {
    max-width: 100%;
    height: auto;
    display: block;
    outline: 0 !important;
}

.flex img {
    width: 100%;
}
        
/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/

h1 {
    font-size: calc(1 * var(--scale-factor)rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
    text-transform: uppercase;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
    text-transform: uppercase;
}

h3 {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
    text-transform: uppercase;
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
    background: var(--menu-bg-color);
    text-transform: uppercase;
}

.menu.menu li {
    margin: 0;
}

.menu.menu li a {
    padding: .5rem .8rem;
    color: var(--menu-text-color);
    background: var(--menu-text-bg-color);
}

.menu.menu li.active a, .menu.menu li a:hover {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
    font-weight: 700;
}

/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider {
    margin: 0;
}

.slick-slide {
    height: var(--slick-slider-height);
    background-size: cover;
    background-position: center center;
}

.slidertext {
    position: absolute;
    top: 70vh;
    background: rgba(255,255,255,.9);
    color: #000;
    display: inline-block;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-70%);
    transform: translateY(-70%);
    padding: 0 1rem;
    z-index: 10;
}

.slidertext h1 {
    font-size: calc(1 * var(--scale-factor)rem);
}


/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

body.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%;
}

header, footer {
    flex: none;
}

/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/

.boltform *, .boltform input[type] {
    border-radius: 0px;
}

.boltform {
    background: var(--main-color);
    color: var(--header-text-color);
    padding: 1rem;
}

.boltform input, .boltform textarea {
    width: 100%;
}

.boltform button {
    background: #121212;
    color: #fff;
    border: none;
    margin: 0;
}

.boltform button:hover {
    background: color(#121212 lightness(60%));
    color: #fff;
}

.boltform .boltforms-row label.required:after {
    content: " *";
    color: var(--menu-text-bg-hover-color);
}



.intenditfooter {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.4);
    margin: 0 .1rem 0 0;
    text-align: right;
}

.intenditfooter a {
    text-decoration: none;
}

.boxes {
    overflow: hidden;
}


.social {

    display: inline;
    

}

.multicolumns figure {
    margin: 0;
    width: 100%;
}

.multicolumns .block .imgwrapper {
    position: relative;
}

.portfoliointro {
    /*background: #e3e3e3;*/
    color: #121212;
    width: 100%;
    /*margin-bottom: 4.7rem;*/
    position: fixed;
    top:16vh;
    padding: 0 15rem;
}

.portfoliointro h1, .portfoliointro p {
    padding: 0;
    margin: 0;
    /*font-size: 1rem;
    font-weight: 400;
    text-align: center;*/
}

.boxwrapper {
    position: relative;
    /* text-shadow: 1px 2px 2px rgba(0,0,0,0.3); */
    text-transform: uppercase;
    display: block;
  
}

.boxwrapper .boxtitle {
    color: #121212;
    display: block;
    padding: 1rem;
    opacity: 0;
}

.boxwrapper .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #121212;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    background-color: rgba(0,0,0,0.0);
}

.boxwrapper:hover .overlay {
    background-color: rgba(255,255,255,0.7);
}

.boxwrapper .overlay span {
    text-align: center;
}

.instagram .boxwrapper .overlay span {
    opacity: 0;
}

.boxwrapper .overlay .fa-icon {
    opacity: 0;
    font-size: 1.5rem;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.boxwrapper .overlay .fa-icon {

    display: inline;
    width:1.7rem;

}

.boxwrapper:hover .overlay .fa-icon, .boxwrapper:hover .overlay .boxtitle, .instagram .boxwrapper:hover .overlay span {
    opacity: 1;
}

.boxwrapper:hover .overlay img {
    -webkit-filter: none;
    filter: none;
}

.clients .content ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.clients .content ul li {
    display: inline-block;
    width: 32%;
}

.about .content ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.about .content ul li {
    /* display: inline-block; */
    width: 50%;
}

.mfp-bg {
    background: #fff;
    opacity: 1;
    filter: alpha(opacity=100);
}

.mfp-figure:after {
    box-shadow: none;
}

#baguetteBox-overlay.visible {

}

#baguetteBox-overlay .full-image img {
    box-shadow: none;
}



/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 50vh;
}

.map-canvas img {
}

@media (max-height: 700px) {
 :root {
    --fixed-header-height: 20vh; 
    }
}

@media (max-width: var(--breakpoint-m)) {
      
    .grid-item { width: calc(25% - 0.5rem); margin: 0.25rem }
    
    h1 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
    
   

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
    
     .logo,
    .logo h1 {
        font-size: calc(1.3 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    .grid-item { width: calc(33% - 0.5rem); margin: 0.25rem }
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
     .logo,
    .logo h1 {
        font-size: calc(1.3 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }
    
    .social {
    margin-top: 2rem;

            text-align: center;
    display: block;

}
    
        .social a {
    margin: .7rem;

}
    
    footer .social .fa-icon {
    height: 22px;
    width: auto;
}

    
}

@media (max-width: var(--breakpoint-xs)) {
    
    .grid-item { width: calc(50% - 0.5rem); margin: 0.25rem }
    h1 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    
     .logo {
        font-size: calc(1.6 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }


.clients .content ul li {
    display: block;
    
}
    
}

@media (max-width: var(--breakpoint-menu)) {
    
    nav {
    
    text-transform: uppercase;
}
    .menu.menu {
    
    background: rgba(243,243,243,1);
    }
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
    }
}
/*--------------------SUPPORT--------------------*/
.grid-item { 
    width: 20%;
    width: -webkit-calc(20% - 0.5rem);
    width: -moz-calc(20% - 0.5rem);
    width: calc(20% - 0.5rem);
    margin: 0.25rem;
}
